//! button
.com_btn{
  padding: 10px 16px;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  letter-spacing: normal;
  box-sizing: border-box;
  height: 36px;
  &.has_sub{
    flex-direction: column;
  }
  &_wrap {
    @include flex(between);
    .com_btn{
      &+.com_btn, &+.com_check_box{
        margin-left:10px;
      }
    }
    .com_check_box{
      &+.com_check_box, &+.com_btn{
        margin-left:10px;
      }
    }
    &.center {
      justify-content: center;
      .com_btn {
        min-width: 100px;
      }
    }
    &.right {
      justify-content: flex-end;
      .com_btn {
        min-width: 100px;
      }
    }
    .re_box + &{
      margin-top:20px;
    }
  }
  &+.com_btn {
    margin-left: 10px;
  }
  &.full {
    width: 100%;
    min-width: 100%;
  }
  &.btn_plus {// 등록 + 버튼
    min-width: 111px;
    font-weight: bold !important;
  }
  //&컬러 color
  &.orange {
    background-color:#ff8933;
    color: $white;
  }
  &.black {
    background-color: $fc-default;
    color: $white;
  }
  &.gray{
    background-color: $gray;
    color:$white;
  }
  &.lightGray {
    background-color: $lightGray;
    color: $fc-default;
  }
  &.red {
    background-color: $red;
    color: $white;
  }
  &.blue {
    background-color: $point;
    color: $white;
  }
  //&유형 type
  &.line {
    background: $white;
    border: 1px solid $point;
    color: $point;
    &.white {
      border-color: $white;
    }
    &.lightGray {
      border-color: $lightGray;
      color:$fc-default;
    }
    &.gray {
      border-color: $gray;
      color: $fc-default;
    }
    &.red {
      border-color: $red;
      color: $red;
    }
    &.black {
      border-color: $fc-default;
      color: $fc-default;
    }
    &.b {
      border-width: 2px;
    }
  }
  //&사이즈 size
  &.l {
    height: 60px;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.4;
  }
  &.m {
    height: 50px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2;
  }
  &.s {
    height: 40px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.14;
    letter-spacing: normal;
  }
  &.xs{
    height: 36px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.14;
    letter-spacing: normal;
  }
  &.xxs{
    height: 30px;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.15;
    letter-spacing: normal;
  }
  &.wl {
    width: 100px;
  }
  &.ws {
    width: 40px;
  }
  &.oval {
    border-radius: 30px;
  }
  &.bold {
    font-weight: bold;
    font-size: 16px;
  }
  &.icon_search {
    padding: 0 !important;
    &::before {
      @include beforeafter;
      background: url('../../img/icon_search_white.png') no-repeat 0 0;
      background-size: contain;
      width: 24px;
      height: 24px;
    }
    &.xs {
      width: 36px;
    }
  }
  &.btn_request {
    background: $lightOrange;
    color: $point;
    border: none;
    width: auto;
    &.red {
      background: $lightRed;
      color: $red;
    }
    &.gray {
      background: $c-border;
      color: $black;
      font-weight: bold;
    }
  }
  .sub{
    font-size:12px;
  }
}

/*!페이징 << < > >> */
.com_paging {
  margin-top: 20px;
  text-align: center;
  .com_table + &{
    margin-top:40px;
  }
  :is(.btn_first, .btn_prev, .btn_page, .btn_next, .btn_last) {
    min-width: 36px;
    height: 36px;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    margin-left: 0;
  }
  :is(.btn_first, .btn_prev, .btn_next, .btn_last) img {
    height: 18px;
  }
  :is(.btn_first, .btn_prev) {
    transform: rotate(180deg);
  }
  .btn_prev {
    margin-right: 10px;
  }
  .btn_next {
    margin-left: 10px;
  }
  .btn_page {
    line-height: inherit;
    &.active {
      font-weight: bold;
      color: $point;
    }
    &.disabled {
      cursor: auto;
      opacity: 0.4
    }
  }
}

//!checkbox
.com_chk {
  display: none;
  &+label,
  &+label:before {
    cursor: pointer;
  }
  &+label {
    display: inline-block;
    padding-left:23px;
    white-space: nowrap;
    &:before {
      content: '';
      display: inline-block;
      width: 22px;
      height: 22px;
      margin-right: 5px;
      margin-left: -23px;
      vertical-align: -6px;
      background:url(../../img/icon_chk_off.png) no-repeat center;
      background-size: 22px auto;
    }
  }
  &:checked+label:before {
    background:url(../../img/icon_chk_on.png) no-repeat center;
    background-size: 22px auto;
  }
  &.circle{
    &+label {
      position: relative;
      padding-left: 26px;
      &:before {
        width: 20px;
        height: 20px;
        background: url("../../img/icon_rdo_off.png") no-repeat;
        background-size: 20px auto;
        margin-right: 5px;
        vertical-align: -5px;
        position: absolute;
        left: 24px;
        top: 50%;
        margin-top: -10px;
      }
    }
    &:checked+label:before {
      background: url("../../img/icon_rdo_on.png") no-repeat;
      background-size: 20px auto;
    }
  }
  &.btn{
    &+label{
      border-radius: 5px;
      background-color: $bg;
      padding: 10px;
      text-align: center;
      &:before{
        display: none;
      }
    }
    &:checked+label {
      background: #6d747f;
      color:#fff;
    }
  }
}

//!탭
.com_tab{
  @include flex(y);
  padding-bottom: 7px;
  gap: 5px;
  .tab{
    position: relative;
    min-width: 90px;
    height: 40px;
    padding: 0 10px;
    font-weight: bold;
    line-height: 1.14;
    letter-spacing: -0.35px;
    text-align: center;
    color: $fc-default;
    background: $lightGray;
    border-radius: 5px;
    @include flex(all);
    white-space: nowrap;
    &.red{
      color:$red;
      background-color: $lightRed;
    }
    &.blue{
      color:$blue;
      background-color: $lightBlue;
    }
    &.active, &:hover{
      background: $point;
      color: $white;
      &::after {
        @include beforeafter;
        border: 7px solid transparent;
        border-top-color: $point;
        position: absolute;
        left: 50%;
        bottom: -14px;
        margin-left: -5px;
      }
    }
  }
  &.arrow_s {
    .tab {
      min-width: 77px;
    }
  }
  &.s {
    .tab {
      min-width: 77px;
      height: 30px;
      &::after {
        display: none;
      }
      &.active, &:hover{
        background: $fc-default;
      }
    }
  }
  &.full {
    width: 100%;
    .tab {
      flex: 1;
    }
  }
}

.com_check_date {
  @include flex(s);
  .chk_date {
    display: none;
    &+label,
    &+label:before {
      cursor: pointer;
    }
    &+label {
      @include flex(all);
      margin-top: 10px;
      width: 32px;
      height: 32px;
      border-radius: 100%;
      margin-right: 3px;
      margin-left: 0 !important;
      color: $gray;  
      border: solid 1px $c-border;
      background-color: $white;
    }
    &:checked+label {
      background-color: $point;
      color: $white;
      font-weight: bold;
      border: none;
    }
  }
}
.com_check_box {
  display: inline-block;
  height: 40px;
  padding: 10px 15px;
  border-radius: 5px;
  background: $c-border;
  &+&{
    margin-left:10px;
  }
}