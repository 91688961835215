/*!스크롤*/
* {
  scrollbar-face-color: rgba(0,0,0,.1);
  /*스크롤*/
  scrollbar-highlight-color: #d5d3d7;
  /*하이라이트*/
  scrollbar-3dlight-color: #d5d3d7;
  /*왼쪽외곽음영*/
  scrollbar-darkshadow-color: #d5d3d7;
  /*오른쪽외곽음영*/
  scrollbar-shadow-color: #d5d3d7;
  /*오른쪽 안쪽음영*/
  scrollbar-arrow-color: #d5d3d7;
  /*화살*/
  scrollbar-track-color: rgba(255, 255, 255, 0); 
  /*스크롤영역*/

  &::-webkit-scrollbar {
    opacity: 0;
    width: 10px;
    height: 10px;
  }

  /*스크롤바*/
  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }

  /*스크롤바 버튼(아래 위 화살표)*/
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .25);
    background-clip: padding-box;
    border: 2px solid transparent;
    border-radius: 10px;
  }

  /*스크롤바 핸들*/
  &::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0);
  }

  /*스크롤바 진행줄*/
  &::-webkit-scrollbar-track-piece {
    background-color: rgba(0,0,0,.05);
  }

  /*진행줄에서 핸들로 덮이지 않은 영역 */
  &::-webkit-scrollbar-corner {
    //background: rgba(0,0,0,.05)
  }

  /*상하 좌우 스크롤이 만나는 공간*/
}

canvas{width:100% !important;}