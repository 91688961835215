// !popup 공통
.com_popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  .bg_close {//bg클릭시 팝업이 닫히게 하기위함
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.7);
  }
  .pop_container {
    position: relative;
    background-color: #fff;
    margin-inline: auto;
    z-index: 101;
    overflow: hidden;
    border-radius: 5px;
    min-width: 300px;
    max-width: 500px;
    &.l{
      min-width:1000px;
    }
    &.m{
      min-width:750px;
    }
    &.s{
      width:500px;
    }
    &.xs{
      width:300px;
    }
  }
  .content_container {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(80vh - 180px);
    padding: 10px $pd-inline 20px;
    scrollbar-face-color: #999;
    /*스크롤*/
    scrollbar-highlight-color: #999;
    /*하이라이트*/
    scrollbar-3dlight-color: #999;
    /*왼쪽외곽음영*/
    scrollbar-darkshadow-color: #999;
    /*오른쪽외곽음영*/
    scrollbar-shadow-color: #999;
    /*오른쪽 안쪽음영*/
    scrollbar-arrow-color: #999;
    /*화살*/
    scrollbar-track-color:#999;
    /*스크롤영역*/
    overflow: auto;
    text-align: center;
    line-height: 1.43;
    color: $black;
    &::-webkit-scrollbar {
      opacity: 0;
      width: 10px;
      height: 10px;
    }
    /*스크롤바*/
    &::-webkit-scrollbar-button {
      width: 0;
      height: 0;
    }

    /*스크롤바 버튼(아래 위 화살표)*/
    &::-webkit-scrollbar-thumb {
      background-color: #999;
      background-clip: padding-box;
      //border: 4px solid transparent;
      //border-radius: 10px;
    }

    /*스크롤바 핸들*/
    &::-webkit-scrollbar-track {
      background-color: #f8f8f8;
    }

    /*스크롤바 진행줄*/
    &::-webkit-scrollbar-track-piece {
      background-color: #f8f8f8;
    }

    /*진행줄에서 핸들로 덮이지 않은 영역 */
    &::-webkit-scrollbar-corner {
      background: #f8f8f8;
    }
    .btn_container {
      padding: 0 0 30px;
    }
    &+.btn_container{
      padding: 0 0 30px;
    }
  }
  .btn_container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding:10px 25px 30px;
    position:relative;
    .com_btn{
      min-width: 120px;
    }
    .right{
      position:absolute;
      right:$pd-inline;
      bottom:30px;
    }
  }
  .pop_tit {
    padding:30px $pd-inline 0;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.56;
    letter-spacing: -0.4px;
    text-align: center;
    color: #222;
    &.line{
      margin-inline: $pd-inline;
      padding-bottom: 17px;
      border-bottom:1px solid $c-border;
    }
    &__wrap{
      position:relative;
      margin-inline: $pd-inline;
      padding:19px $pd-inline 20px;
      border-bottom:1px solid $c-border;
      .pop_tit{
        position:absolute;
        left:50%;
        top:-4px;
        transform: translate(-50% , 0);
      }
      &.pd0 {
        padding: 19px 0;
      }
    }
  }
  .pop_desc {
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: -0.35px;
    color: $gray;
    text-align: center;
    margin-top: 4px;
  }
  .btn_close {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 18px;
    height: 18px;
    background-image: url(../../img/icon_close.png);
    background-size: 18px;
    background-repeat: no-repeat;
  }
}
