//!^text style

.com_point_txt {
  color: $point !important;
}
.com_blue_txt {
  color: $blue !important;
}
.com_red_txt {
  color: $red !important;
}
// !page title
.text_center{
  text-align: center;
}
.pg_tit{
  font-size: 18px;
  font-weight: bold;
  line-height: 1.56;
  letter-spacing: -0.4px;
  color: $black;
}
.com_pg_tit {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.56;
  letter-spacing: -0.4px;
  color: $black;
  @include dot(8px,8px,5px,$point);
  &+.com_board_list, &+.com_layout{
    margin-top:20px;
  }
  &__wrap{
    @include flex(between);
    padding-bottom: 13px;
    .com_pg_tit {    
      // @include flex(y);
      // padding: 5px 0;
      // &.icon {
      //   &::before {
      //     @include beforeafter;
      //     width: 30px;
      //     height: 30px;
      //     border-radius: 30px;
      //     margin-right: 8px;
      //     background-color: $point;
      //     background-size: 18px !important;
      //     opacity: 1;
      //   }
      // }
      // .bar {
      //   @include divider(1px,12px,10px);
      //   &::after {
      //     vertical-align: middle;
      //   }
      // }
      // .point_txt {
      //   color: $point;
      //   font-size: 15px;
      // }
    }
    .com_btn_wrap {
      margin-top: 0px;
      .select {
        width: 150px;
      }
    }
  }
}
