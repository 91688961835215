.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
[data-column-id="col-1"].rdt_TableCol {
  border-right: 1px solid #d3d3d3;
  border-left: 1px solid #d3d3d3;
  justify-content: center;
  font-weight: bold;
  font-size: 15px;
}
[data-column-id="col-2"].rdt_TableCol {
  border-right: 1px solid #d3d3d3;
  justify-content: center;
  font-weight: bold;
  font-size: 15px;
}
[data-column-id="col-3"].rdt_TableCol {
  border-right: 1px solid #d3d3d3;
  justify-content: center;
  font-weight: bold;
  font-size: 15px;
}
[data-column-id="col-4"].rdt_TableCol {
  justify-content: center;
  font-weight: bold;
  font-size: 15px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #343a40;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
