//^board style
//!list
.com_board_list{
  margin-top: 10px;
  margin-bottom:10px;
  border-top:1px solid $c-border;
  border-bottom: 1px solid $c-border;
  .com_pg_tab + &{
    margin-top:30px;
  }
  &__top{
    margin-block:20px 13px;
    background: rgba(0,0,0,.01);
    border:1px solid $c-border;
    padding:10px 20px;
    border-radius: 10px;
    box-shadow: 2px 2px 2px rgba(0,0,0,.05);
    @include flex(between);
    .select {
      width: 150px;
      min-width: 150px;
      height: 40px;
      &+.com_tab{
        margin-left:10px;
      }
    }
    .tit {
      margin: 0 10px;
      font-weight: bold;
      white-space: nowrap;
    }
    &+.com_btn_wrap{
      margin-top:20px;
    }
  }
  &__bottom{
   position:relative;
    .com_btn_wrap{
      position:absolute;
      right:0;
      bottom:0;
      &.left {
        left: 0;
        right: initial;
      }
    }
  }
  :is(th,td){
    border-top:1px solid $c-border;
    border-left: 1px solid $c-border;
    text-align: center;
    font-size: 14px;
    letter-spacing: normal;
  }

  tr.selected {
    td {
      background-color: $lightOrange;
    }
  }
  th,td {
    &:is(:first-child) {
      border-left: none;
    }
    &.border_left {
      border-left: 1px solid $c-border;
    }
  }
  th{
    color: $black;
    font-weight: bold;  
    padding:12px 5px;
    background-color: #f0f1f3;
    .sub{
      font-size: 12px;
      font-weight: normal;
      line-height: 1.25;
      letter-spacing: -0.3px;
      display: block;
    }
    .sort{
      @include flex(all);
    }
  }
  td{
    position: relative;
    padding: 5px 5px;
    .input {
      &.ws {
        width: 150px;
      }
    }
    .txt {
      font-size: 14px;
      &.has_btn {
        padding: 10px 5px;
        margin-right: 6px;
        color: $point;
        @include flex(all);
        &::after {
          content:'';
          display: inline-block;
          background: url('../../../assets/img/icon_close.png') no-repeat center center #eee;
          background-size: 8px;
          width: 15px;
          height: 15px;
          border-radius: 10px;
          margin-left: 4px;
          cursor: pointer;
        }
      }
    }
    &.td_date {
      font-size: 13px;
    }
    .btn_edit {
      position: absolute;
      top:50%;
      right:5px;
      transform: translate(0,-50%);
      width: 16px;
      height: 16px;
      &::after {
        background-color: #eaebed;
        border-radius: 100%;
        @include beforeafter;
        @include addImg(icon_edit,16,16,10px 10px);
      }
    }
    &.has_btn_edit{
      padding-inline: 33px;
    }
    //^td text color
    &.td_{
      &red{
        color: $red;
        .txt,.name {
          color: $red;
        }
      }
      &point {
        color: $blue;
        .txt,.name {
          color: $blue;
        }
      }
      &green {
        color: $green;
        .txt,.name {
          color: $green;
        }
      }
    }
    //^ td background color
    &.highlight {
      background-color: $lightOrange;
    }
    &.bg_col{
      background-color: $bg-row;
    }
    &.bg_gray{
      background-color: #f8f9fa;
    }
    &.td_left {
      text-align: left;
      padding-inline:15px;
    }
    &.td_right {
      text-align: right;
      padding: 0 15px;
    }
    &.bold {
      font-weight: bold;
    }
    .memo {
      @include multi-ellipsis(1);
    }
    .link {
      font-size: 14px;
    }
  }
  .com_btn{
    min-width: auto;
    padding-inline: 14.5px;
    &.s{
      min-height: 30px;
      height: 30px;
      padding-block: 0;
    }
  }
  &.type_row {
    th {
      text-align: left;
      padding-inline: 15px;
      word-break: break-all;
    }
    td {
      padding: 7px 10px;
      height: 50px;
      text-align: left;
      label + input[type=checkbox] + label, 
      label + input[type=radio] + label{
        margin-left:20px;
      }
      .input{
        width:100%;
      }
    }
  }
  &.type_bg_blue {
    th {
      background: #eaf2fc;
    }
  }
  &.type_bg_red {
    th {
      background: $lightRed;
    }
  }
  &__scroll {
    overflow-y: auto;
    thead{
      position:sticky;
      top:-1px;
      z-index: 1;
    }
    &+.com_btn_wrap{
      margin-top:20px;
    }
  }
  &+.com_btn_wrap{
    margin-top:20px;
  }
  .select {
    &.ws {
      width: 170px;
    }
  }
}

.com_board_search {
  @include flex(y);
  gap: 10px;
  margin-bottom:0;
  .com_btn_wrap {
    .com_btn {
      width: 50px;
      // border: 1px solid #999;
      color: $fc-default;
      & + .com_btn {
        margin-left: 5px;
      }
      &.active {
        background: $gray;
        border: 1px solid #999;
        color: $white;
      }
    }
  }
  .option{
    .com_btn{
      height:40px;
      font-weight:bold;
      &+.com_btn, &+.com_check_box{
        margin-left:5px;
      }
    }
    .com_check_box{
      &+.com_btn, &+.com_check_box{
        margin-left:5px;
      }
      label{
        font-weight: bold;
      }
    }
  }
  .com_datepicker {
    height: 40px;
    line-height: 40px;
  }
  .search {
    @include flex(y);
    width: 100%;
  }
  .input {
    margin-bottom: 0;
  }
  .bar {
    @include divider(1px,36px,0px);
    &::after {
      vertical-align: middle;
    }
  }
}


// board box list
.com_board_box {
  overflow: auto;
  margin-top: 10px;
  .board_item {
    position: relative;
    padding: 10px $pd-inline;
    border: 1px solid $c-border;
    border-radius: 5px;
    & + .board_item {
      margin-top: 10px;
    }
    .btn_report {
      position: absolute;
      right: $pd-inline;
      height: 23px;
      padding: 0 5px;
      border-radius: 3px;
      border: solid 1px $gray;
      background-color: $gray;
      font-size: 11px;
      font-weight: 500;
      letter-spacing: -0.28px;
      text-align: center;
      color: $white;
    }
  }
  .info {
    @include flex(s);
    margin-block: 5px;
  }
  .tit {
    display: inline-block;
    min-width: 110px;
    font-weight: normal;
    line-height: 1.38;
    letter-spacing: -0.33px;
    color: $gray;
  }
  .cont {
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: -0.35px;
    &.date {
      color: $gray;
      > p {
        margin-bottom: 6px;
      }
    }
  }
  .bold {
    font-weight: bold;
  }
  .dot {
    @include dot(2px,2px,5px,$gray);
    &::before {
      vertical-align: 3px;
      margin-left: 0;
    }
  }
  .btn_edit {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .btn_wrap {
    margin-left: auto;
    .btn_edit {
      position: relative;
      top: initial;
      right: inherit;
    }
    .btn_delete {
      margin-left: 10px;
    }
  }
  .board_subject {
    @include flex(y);
    margin: 5px 0 3px;
    .name {
      font-weight: normal;
    }
  }
  .name {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.56;
    letter-spacing: -0.4px;
    color: $black;
  }
  .desc {
    display: inline-block;
    margin-left: 5px;
    font-size: 12px;
    font-weight: normal;
    color: #929ca8;
    vertical-align: 1px;
    &+.btn_change{
      margin-left:10px;
    }
  }
  .date {
    display: inline-block;
    margin-left: 5px;
    font-size: 12px;
    font-weight: normal;
    color: #6d747f;
  }
  .com_divider {
    margin: 8px 0;
  }
  .total {
    .tit {
      min-width: auto;
      &.com_red_txt {
        color: $red;
      }
    }
    .bar {
      @include divider(1px,12px,10px);
      &::after {
        vertical-align: middle;
      }
    }
    .cont {
      margin-left:5px;
    }
  }
  .detail_tit {
    margin-top: 10px;
  }
  &+.com_btn_wrap{
    margin-top:20px;
  }
}

// detail 
.com_detail_top{
  @include flex(between);
  margin-bottom:9px;
  gap:10px;
  .state{
    @include inflex(all);
    min-width: 100px;
    height: 40px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.14;
    letter-spacing: -0.35px;
    text-align: center;  
    background-color: $lightOrange;
    color: $point;
    &.stop{
      background-color: $lightRed;
      color: $red;
    }
  }
  .dot {
    @include dot(2px,2px,5px,$gray);
  }
  .info{
    @include inflex(y);
    flex:1;
    height: 40px;
    border-radius: 5px;
    background-color: #f0f1f3; 
    padding-inline: 20px;  
    .tit{
      font-size: 14px;
      font-weight: bold;
      letter-spacing: -0.35px;
      color: #1e293b;
    }  
    .date{
      font-size: 12px;
      letter-spacing: -0.3px;
      color: #6d747f;  
    }
    .txt{
      font-size: 13px;
      letter-spacing: -0.3px;
      color: #6d747f;  
    }
    &.stop{
      background-color: $lightRed;
      .tit{
        color:$red;
      }
      .dot::before{
        background-color: $red;
      }
      .txt{
        color:$red;
      }
    }
  }
}

//tree menu
.com_tree_menu{
  height: calc(100vh - 180px); 
  border:1px solid rgba(0,0,0,.25); 
  overflow-y: auto;
  .menu_wrap{
    margin-bottom:15px;
    &:last-child{
      margin-bottom:0;
    }
    .depth1{
      background:$bg;
      padding:5px 15px;
      @include flex(between);
      font-weight: bold
    }
    .depth2{
      @include dot(7px, 1px, 5px, #888);
      padding:5px 10px;
      color:#555;
      position:relative;
      &::after{
        @include beforeafter;
        width:1px;
        height:5px; 
        background-color: #888;
        position:absolute;
        top:11px;
        left:15px;
      }
    }
  }
  &+.section{
    .com_board_list{
      margin-top:0;
    }
  }
}
